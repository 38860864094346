export const DASHBOARD = "/";
export const LOGIN = "/login";
export const REGISTER = "/register";
export const OTP = "/otp";
export const FORGOT_PASSWORD = "/forgot-password";
export const LEAD = "/leads";
export const ASSETS = "/assets-websites";
export const RESET_PASSWORD = "/reset-password";
export const PROFILE = "/profile";
export const CATEGORY_LIST = "/admin/category";
export const CATEGORY_ADD = "/admin/category/create";
export const CATEGORY_EDIT = "/admin/category/edit/:id";
export const CITY_LIST = "/admin/city";
export const CITY_ADD = "/admin/city/create";
export const CITY_EDIT = "/admin/city/edit/:id";
export const TEAM_LIST = "/admin/team";
export const TEAM_ADD = "/admin/team/create";
export const TEAM_EDIT = "/admin/team/edit/:id";
export const LAWYER_LIST = "/admin/lawyer";
export const LAWYER_ADD = "/admin/lawyer/create";
export const LAWYER_EDIT = "/admin/lawyer/edit/:id";
export const SECTION_LIST = "/admin/section";
export const SECTION_ADD = "/admin/section/create";
export const SECTION_EDIT = "/admin/section/edit/:id";
export const REVIEW_LIST = "/admin/review";
export const REVIEW_ADD = "/admin/review/create";
export const ROLE_LIST = "/admin/role";
export const ROLE_ADD = "/admin/role/create";
export const ROLE_EDIT = "/admin/role/:id";
export const PAGE_LIST = "/admin/page";
export const PAGE_ADD = "/admin/page/create";
export const PAGE_EDIT = "/admin/page/edit/:id";
export const ATTRIBUTE_LIST = "/admin/attribute";
export const ATTRIBUTE_ADD = "/admin/attribute/create";
export const ATTRIBUTE_EDIT = "/admin/attribute/edit/:id";
export const TYPE_LIST = "/admin/type";
export const TYPE_ADD = "/admin/type/create";
export const TYPE_EDIT = "/admin/type/edit/:id";
export const DOCUMENT_TEMPLATE_LIST = "/admin/document-template";
export const DOCUMENT_TEMPLATE_ADD = "/admin/document-template/create";
export const DOCUMENT_TEMPLATE_EDIT = "/admin/document-template/edit/:id";
export const CLIENT_LIST = "/admin/client";
export const SUBSCRIPTIN_LIST = "/admin/subscription";
export const SUBSCRIPTIN_ADD = "/admin/subscription/create";
export const SUBSCRIPTIN_EDIT = "/admin/subscription/edit/:id";
export const KNOWLEDGEBANK_LIST = "/admin/knowledgebank/:language";
export const KNOWLEDGEBANK_ADD = "/admin/knowledgebank/create";
export const KNOWLEDGEBANK_EDIT = "/admin/knowledgebank/edit/:id";
export const SUBSCRIBER_LIST = "/admin/subscriber";
export const SETTING = "/admin/setting";
export const REDIRECTURL_LIST = "/admin/redirect-url";
export const REDIRECTURL_ADD = "/admin/redirect-url/create";
export const REDIRECTURL_EDIT = "/admin/redirect-url/edit/:id";
export const PACKAGES_LIST = "/admin/packages";
export const PACKAGES_ADD = "/admin/packages/create";
export const PACKAGES_EDIT = "/admin/packages/edit/:id";
export const LEGAL_DOCUMENT_LIST = "/admin/legal-document";
export const LEGAL_DOCUMENT_ADD = "/admin/legal-document/create";
export const LEGAL_DOCUMENT_EDIT = "/admin/legal-document/edit/:id";
export const COURT_LIST = "/admin/court";
export const COURT_ADD = "/admin/court/create";
export const COURT_EDIT = "/admin/court/edit/:id";
export const BENCH_ADD = "/admin/court/bench/create";
export const BENCH_EDIT = "/admin/court/bench/edit/:id";
